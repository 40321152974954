<template>
  <div class="content-wrapper">
    <div class="pgTitle">Resources</div>
    <div class="resources">
      <div class="navigation">
        <button
          class="tab"
          :disabled="tab.enabled == false"
          v-for="tab in activeTabs"
          v-bind:key="tab.name"
          v-bind:class="['tab', { active: currentTab === tab.name }]"
          v-on:click="setCurrentTab(tab.name, tab.resourceType)"
        >
          <span>{{tab.name}}</span>
        </button>
      </div>
      <div class="main">
        <keep-alive>
          <component v-bind:is="currentTabComponent" :resourceType="currentResourceType" />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import VIDEOS from '../components/resources/videos/VideosListing.vue';
import DOCUMENTS from '../components/resources/documents/DocumentsListing.vue';
import GUILDVIDEOS from '../components/resources/guildVideos/GuildVideosListing.vue';
import GUILDDOCUMENTS from '../components/resources/guildDocuments/GuildDocumentListing.vue';
import RESOURCE_VIDEOS from '../components/resources/libraryVideos/ResourceVideoListing.vue';
// eslint-disable-next-line
import RESOURCE_DOCUMENTS from '../components/resources/libraryDocuments/ResourceDocumentListing.vue';
import TRYTHIS_VIDEOS from '../components/resources/libraryVideos/TryThisVideoListing.vue';
// eslint-disable-next-line
import LUXURY_RETAIL_WINS_DOCUMENTS from '../components/resources/libraryDocuments/ResourceDocumentListing.vue';
import JOURNEY_MAPS from '../components/resources/journey/JourneyMapsListing.vue';
import ALTERNATIVE_TRANSPORTATIONS from '../components/resources/alternativeTransportation/AlternativeTransportationList.vue';
import LINCOLN_VITRINE from '../components/resources/lincolnVitrine/LincolnVitrine.vue';
import INTENSIVE_ASSET from '../components/resources/intensive/IntensiveAssets.vue';
import CLUBHOUSE from '../components/resources/clubhouse/ClubhouseListing.vue';
import ResourceHandlers from '../mixins/resourceHandlers.vue';
import { clearSessionItem } from '../utils';

export default {
  name: 'Resources',
  components: {
    VIDEOS,
    DOCUMENTS,
    'TRY THIS': TRYTHIS_VIDEOS,
    'LUXURY RETAIL WINS': LUXURY_RETAIL_WINS_DOCUMENTS,
    'GUILD VIDEOS': GUILDVIDEOS,
    'GUILD DOCUMENTS': GUILDDOCUMENTS,
    'RESOURCE VIDEOS': RESOURCE_VIDEOS,
    'RESOURCE DOCUMENTS': RESOURCE_DOCUMENTS,
    'CLIENT EXPERIENCE MAPS': JOURNEY_MAPS,
    'ALTERNATIVE TRANSPORTATION': ALTERNATIVE_TRANSPORTATIONS,
    'LINCOLN VITRINE': LINCOLN_VITRINE,
    '2024 INTENSIVE': INTENSIVE_ASSET,
    'LINCOLN CLUB HOUSE': CLUBHOUSE,
  },
  mixins: [ResourceHandlers],
  data() {
    return {
      tabs: [
        {
          name: 'CLIENT EXPERIENCE MAPS', enabled: true, permission: 'USER', resourceType: 'JOURNEY_MAPS',
        },
        {
          name: 'ALTERNATIVE TRANSPORTATION', enabled: true, permission: 'USER', resourceType: 'ALTERNATIVE_TRANSPORTATIONS',
        },
        {
          name: 'LINCOLN VITRINE', enabled: true, permission: 'USER', resourceType: 'LINCOLN_VITRINE',
        },
        {
          name: 'TRY THIS', enabled: true, permission: 'USER', resourceType: 'TRYTHIS_VIDEOS',
        },
        {
          name: 'LUXURY RETAIL WINS', enabled: true, permission: 'USER', resourceType: 'LUXURY_RETAIL_WINS_DOCUMENTS',
        },
        {
          name: '2024 INTENSIVE', enabled: true, permission: 'USER', resourceType: 'INTENSIVE_ASSET',
        },
        {
          name: 'VIDEOS', enabled: true, permission: 'USER', resourceType: '',
        },
        {
          name: 'DOCUMENTS', enabled: true, permission: 'USER', resourceType: '',
        },
        {
          name: 'eLEARNING', enabled: false, permission: 'USER', resourceType: '',
        },
        {
          name: 'LINCOLN CLUB HOUSE', enabled: true, permission: 'USER', resourceType: 'CLUBHOUSE',
        },
        {
          name: 'GUILD VIDEOS', enabled: true, permission: 'GUILD', resourceType: 'GUILD',
        },
        {
          name: 'GUILD DOCUMENTS', enabled: true, permission: 'GUILD', resourceType: 'GUILD',
        },
        {
          name: 'RESOURCE VIDEOS', enabled: true, permission: 'RESOURCE_LIBRARY', resourceType: 'RESOURCE_LIBRARY',
        },
        {
          name: 'RESOURCE DOCUMENTS', enabled: true, permission: 'RESOURCE_LIBRARY', resourceType: 'RESOURCE_DOCUMENTS',
        },
      ],
      currentTab: 'CLIENT EXPERIENCE MAPS',
      currentResourceType: 'JOURNEY_MAPS',
    };
  },
  computed: {
    currentTabComponent() {
      return this.currentTab;
    },
    isGuild() {
      return this.$store.getters['userStore/isGuild'];
    },
    isAdmin() {
      return this.$store.getters['userStore/isAdmin'];
    },
    testIsAdmin() {
      return 'GUILD';
    },
    activeTabs() {
      const outerThis = this;

      return this.tabs.filter((u) => {
        if (u.permission === 'USER') {
          return u;
        } if (u.permission === 'GUILD'
              && (outerThis.$store.getters['userStore/isGuild']
                || outerThis.$store.getters['userStore/isAdmin'])) {
          return u;
        } if (u.permission === 'RESOURCE_LIBRARY'
              && outerThis.$store.getters['userStore/isAdmin']) {
          return u;
        }

        return null;
      });
    },
  },
  created() {
    if (this.$route.params.resourceid) {
      this.setCurrentTab('CLIENT EXPERIENCE MAPS', 'JOURNEY_MAPS');
      if (this.$route.params.doctype === 'v') {
        this.openVideoModal(this.$route.params.resourceid);
      } else {
        this.openDocumentModal(this.$route.params.resourceid);
      }
    }
    clearSessionItem('directlink');
    this.$analytics.trackPageView();
  },
  methods: {
    setCurrentTab(tabName, resourceType) {
      this.currentTab = tabName;
      this.currentResourceType = resourceType;
      this.$store.dispatch('analyticStore/updateLastNavTime');
    },
    async openVideoModal(resourceid) {
      this.$analytics.trackEventBtnPageWithMetaInfo('Video', {
        videoId: resourceid,
      });
      await this.showVideoModal(resourceid);
    },
    async openDocumentModal(resourceid) {
      this.$analytics.trackEventBtnPageWithMetaInfo('Document', {
        documentId: resourceid,
      });
      const docData = await this.$store.dispatch('resourceStore/getDocument', resourceid);
      await this.showDocumentModal(docData.docAssetId, docData.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.pgTitle {
  text-align: center;
  @include font-size(2.875rem);
  margin: 10px 0 20px 0;
}

.resources {
  display: flex;
  flex-wrap: wrap;

  @include respond(md) {
    justify-content: space-between;
  }
}
h2 {
  color: #304048;
}

.main {
  color: #304048;
  flex-basis: 100%;

  @include respond(md) {
    flex-basis: 75%;
  }
}
.navigation {
  flex-basis: 100%;
  margin-bottom: 1em;

  @include respond(md) {
    flex-basis: 22%;
  }
}
button {
  outline: none;
  display: block;
  width: 100%;
  background: #ebe6e3;
  text-align: left;
  margin-left: -1px;
  position: relative;
  line-height: 3em;
  font-size: 1.25rem;
  color: #111010;
  transition: all 0.35s ease;
  border: 3px solid #fff;

  @include respond(md) {
    text-align: center;
  }
}

button:hover {
  cursor: pointer;
  background-color: #304048;
  color: white;
}
button.active {
  background: #304048;
  color: white;
  span {
    padding-bottom: 2px;
    border-bottom: 3px solid $LincolnOrange;
  }
}
button:disabled {
  cursor: default;
  background: #f9f8f7;
  color: #bdbdbd;
}
</style>
