/* eslint-disable */
import apiManager from '../api';

const toggleDocumentEnabled = (id) => {
  let isSuccess = false;
  apiManager.resourceDocument.toggleEnabled(id)
    .then((resp) => {
        isSuccess = resp;
      }
    );

    return isSuccess;
}

const toggleVideoEnabled = (id) => {  
  let isSuccess = false;
  apiManager.resourceVideo.toggleEnabled(id)
    .then((resp) => {
        isSuccess = resp;
      }
    );

    return isSuccess;
}

export {
  toggleDocumentEnabled,
  toggleVideoEnabled,
};