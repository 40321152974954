var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "video" }, [
      _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "thumbnailWrapper" }, [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.openVideoModal($event)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "thumbnail" },
                [
                  _c("AssetDownload", {
                    attrs: {
                      asset: _vm.video.thumbnailAsset,
                      forceImage: _vm.image,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "descWrapper" }, [
          _c(
            "a",
            {
              staticClass: "videoTitle",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.openVideoModal($event)
                },
              },
            },
            [
              _vm._v(
                "\r\n                  " +
                  _vm._s(_vm.video.title) +
                  "\r\n              "
              ),
            ]
          ),
          _c("div", { staticClass: "videoDescription" }, [
            _vm._v(
              "\r\n                " +
                _vm._s(_vm._f("truncate")(_vm.video.description)) +
                "\r\n            "
            ),
          ]),
          _vm.isAdmin
            ? _c(
                "div",
                { staticClass: "toggleSwitch" },
                [
                  _c("ToggleSwitch", {
                    attrs: { value: _vm.video.isEnabled },
                    on: { toggled: _vm.toggleEnabled },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isAdmin
            ? _c(
                "div",
                { staticClass: "editVideo" },
                [_c("ButtonEdit", { on: { callback: _vm.edit } })],
                1
              )
            : _vm._e(),
          _vm.isAdmin
            ? _c(
                "div",
                { staticClass: "deleteVideo" },
                [_c("ButtonDelete", { on: { callback: _vm.deleteConfirm } })],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "linkid" }, [
      _c("span", { staticClass: "label" }, [_vm._v("Resource Direct Link: ")]),
      _c("span", { staticClass: "link" }, [
        _c("a", { attrs: { href: _vm.directLink } }, [
          _vm._v(_vm._s(_vm.directLink)),
        ]),
      ]),
      _c("hr"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }