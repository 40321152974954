<template>
  <div class="imgContainer">
    <div
      class="loadingContainer"
    >
      <font-awesome-icon
        v-if="loading"
        :icon="['fas', 'cog']"
        size="1x"
        class="fa-spin loading"
      />
    </div>
    <div :class="storyTile" :style="setAssetImage"></div>
  </div>
</template>

<script>

import apiManager from '../../api';

export default {
  name: 'AssetDownload',
  props: ['asset', 'isStoryTile', 'forceImage'],
  data() {
    return {
      id: null,
      downloadedImage: null,
      loading: false,
    };
  },
  created() {
    this.getImage();
  },
  watch: {
    profile() {
      this.getImage();
    },
    forceImage(newImageData) {
      this.downloadedImage = newImageData;
    },
  },
  methods: {
    async getImage() {
      if (this.asset && this.asset.id !== this.id) {
        this.loading = true;
        this.id = this.asset.id;
        const download = await apiManager.asset.download(this.id, true)
          .then(resp => resp)
          .catch(() => {
            this.loading = false;
          })
          .finally(() => {
          });

        if (download) {
          this.downloadedImage = window.URL.createObjectURL(
            new Blob([download.data]),
          );
        }
        this.loading = false;
      }
    },
  },
  computed: {
    setAssetImage() {
      let tempPath = '';
      const backgroundImage = {};
      if (this.downloadedImage) {
        tempPath = this.downloadedImage;
      }
      backgroundImage['background-image'] = `url(${tempPath})`;
      return backgroundImage;
    },
    storyTile() {
      if (this.isStoryTile) {
        return 'imgStory';
      }

      return 'imgNormal';
    },
  },
};
</script>

<style lang="scss" scoped>

.imgContainer {
    width: 100%;
    height: 100%;
    position: relative;
    color: black;

    .imgNormal {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .imgStory {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
      background-color: $LincolnGrayLighter;
    }

    .loadingContainer {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: absolute;

    .loading {
      z-index: 100;
      align-self: center;
    }
  }
}
</style>
