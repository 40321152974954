<template lang="html">
<div class="video">
  <div class="wrapper">
    <div class="thumbnailWrapper">
      <a href="#" @click.prevent="openVideoModal">
        <div class="thumbnail">
          <AssetDownload :asset="video.thumbnailAsset" :forceImage="image"/>
        </div>
      </a>
    </div>
    <Accordion class="mb-4" v-if="video.Year === 2024">
      <template v-slot:title>
        <span class="font-semibold text-xl">2024</span>
      </template>
      <template v-slot:content>
        <div class="descWrapper">
          <a href="#" class="videoTitle" @click.prevent="openVideoModal">
            {{video.title}}
          </a>
          <div class="videoDescription">
            {{video.description | truncate}}
          </div>
        </div>
        <div class="deleteVideo" v-if="isAdmin">
          <ButtonDelete @callback="deleteConfirm" />
        </div>
      </template>
    </Accordion>
    <Accordion class="mb-8" v-else-if="video.Year === 2023">
      <template v-slot:title>
        <span class="font-semibold text-xl">2023</span>
      </template>
      <template v-slot:content>
        <div class="descWrapper">
          <a href="#" class="videoTitle" @click.prevent="openVideoModal">
            {{video.title}}
          </a>
          <div class="videoDescription">
            {{video.description | truncate}}
          </div>
        </div>
        <div class="deleteVideo" v-if="isAdmin">
          <ButtonDelete @callback="deleteConfirm" />
        </div>
      </template>
    </Accordion>
    <Accordion class="mb-4" v-else-if="video.Year === 2022">
      <template v-slot:title>
        <span class="font-semibold text-xl">2022</span>
      </template>
      <template v-slot:content>
        <div class="descWrapper">
          <a href="#" class="videoTitle" @click.prevent="openVideoModal">
            {{video.title}}
          </a>
          <div class="videoDescription">
            {{video.description | truncate}}
          </div>
        </div>
        <div class="deleteVideo" v-if="isAdmin">
          <ButtonDelete @callback="deleteConfirm" />
        </div>
      </template>
    </Accordion>
    <div v-else>
      Nothing to see here.
    </div>
  </div>
</div>
</template>

<script lang="js">
import VideoModal from './VideoModal.vue';
import ButtonDelete from '../../formElements/buttons/buttonDelete.vue';
import AssetDownload from '../../asset/AssetDownload.vue';
import Accordion from '../../accordion/Accordion.vue';

export default {
  name: 'VideoPreviewGrid',
  props: ['video', 'defaultImage', 'isAdmin'],
  components: {
    ButtonDelete,
    AssetDownload,
    Accordion,
  },
  data() {
    return {
      image: '',
    };
  },
  watch: {
    defaultImage(newValue) {
      this.image = newValue;
    },
  },
  methods: {
    async deleteConfirm() {
      const vm = this;
      this.$dialog
        .confirm('Are you sure you want to remove this video?', { okText: 'Yes', cancelText: 'No', customClass: '' })
        .then(() => {
          vm.deleteVideo();
          this.$store.dispatch('buttonSubmitStore/done');
        })
        .catch(() => {
          this.$store.dispatch('buttonSubmitStore/done');
        });
    },
    async deleteVideo() {
      await this.$store.dispatch('resourceStore/deleteVideo', { videoId: this.video.id, assetId: this.video.thumbnailAssetId });
      await this.$parent.getVideos();
    },
    openVideoModal() {
      this.$modal.show(VideoModal, { videoId: this.video.videoId }, {
        name: 'VideoModal',
        adaptive: true,
        height: 'auto',
        width: '70%',
        scrollable: true,
      });
    },
  },
  filters: {
    truncate(value) {
      let newValue = value;
      if (value.length > 100) {
        newValue = `${value.substring(0, 100)}...`;
      }
      return newValue;
    },
  },
};
</script>

<style scoped lang="scss">
.video {
  height: 550px;
  flex-basis: 100%;
  width: 259px;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  color: #262a2e;
  display: flex;
  flex-direction: column;

  @media (min-width: 425px) {
    flex-basis: 50%;
  }

  @media (min-width: 640px) {
    flex-basis: 33.33%;
  }

  @include respond(lg) {
    flex-basis: 25%;
  }

  .wrapper {
    background: #ececec;
    height: 100%;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    border-radius: 10px;
    overflow: hidden;

    .thumbnailWrapper {
      margin-bottom: 10px;
      height: 55%;

      .thumbnail {
        width: 100%;
        height: 100%;
      }
    }

    .descWrapper {
      padding: 0 20px 20px 20px;

      .videoTitle {
        @include font-size(1.5rem);
        text-decoration: none;
        color: rgb(50, 64, 74);
        display: block;
        margin-bottom: 10px;

        &:hover {
          color: $LincolnOrange;
        }
      }

      .videoDescription {
        color: #262a2e;
        font-size: 1rem;
      }
    }
  }

  .deleteVideo {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
