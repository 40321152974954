<template>
  <div id="documentSubmission">
    <div class="form-title">Add a Document</div>
    <form @submit.prevent="submit">
      <TextBox
        :label="'Document Title'"
        :name="'title'"
        v-model="documentObject.title"
        :required="true"
        :placeholder="'Document Title *'"
        class="textBox"
      />
      <TextBox
        :name="'description'"
        :required="true"
        v-model="documentObject.description"
        :type="'textarea'"
        :placeholder="'Short description *'"
        class="textBox description"
      />
      <file-upload
        v-model="file"
        accept=".pdf"
        >
      </file-upload>
      <ImageCropperForAsset class="imagePreviewer"
                            :asset="documentObject.thumbnailAsset"
                            @sendImage="sendImage"
                            @uploadImage="uploadImage" />
      <div>Document Listing Preview</div>
      <DocumentPreview
        class="previewBorder"
        :documentObject="documentObject"
        :defaultImage="imageData"
      />
      <ButtonSubmit :copy="'Submit'" @callback="submit" />
      <button type="button" class="cancel" @click="closeModal">Cancel</button>
    </form>
  </div>
</template>

<script>
import TextBox from '../../formElements/TextBox.vue';
import FileUpload from '../../formElements/FileUpload.vue';
import ImageCropperForAsset from '../../imageManipulation/ImageCropperForAsset.vue';
import DocumentPreview from './JourneyMapsPreview.vue';
import ButtonSubmit from '../../formElements/buttons/buttonSubmit.vue';

export default {
  name: 'JourneyMapsSubmission',
  components: {
    TextBox,
    FileUpload,
    ImageCropperForAsset,
    DocumentPreview,
    ButtonSubmit,
  },
  props: ['resourceType'],
  data: () => ({
    file: null,
    imageData: null,
    imageFormData: null,
    documentObject: {
      title: '',
      description: '',
      thumbnailAssetId: null,
      docAssetId: null,
      thumbnailAsset: null,
      documentType: '',
    },
  }),
  methods: {
    async submit() {
      const formDataDoc = new FormData();
      formDataDoc.append('file', this.file);
      const docResp = await this.$store.dispatch(
        'assetStore/setAsset',
        formDataDoc,
      );

      const thumbnailImageUploaded = await this.$store.dispatch('assetStore/setAsset', this.imageFormData);
      this.documentObject.thumbnailAssetId = thumbnailImageUploaded.id;

      this.documentObject.docAssetId = docResp.id;
      if (this.resourceType) {
        this.documentObject.documentType = this.resourceType;
      }

      await this.$store.dispatch('resourceStore/submitDocument', this.documentObject);
      this.$store.dispatch('buttonSubmitStore/done');
      this.closeModal();
    },
    sendImage(image) {
      this.imageData = image;
    },
    async uploadImage(file) {
      this.imageFormData = file;
    },
    closeModal() {
      this.$modal.hide('DocumentSubmission');
    },
  },
};
</script>

<style lang="scss" scoped>
#documentSubmission {
  padding: 2em;
  background-color: white;

  .form-title {
    @include font-size(2rem);
  }
}

.options {
  color: $LincolnGrayLight;
}

.textBox {
  margin: 2em 0;
  display: block;

  &.description {
    /deep/ textarea {
      height: 125px;
    }
  }
}

$arrowWidth: 0.7em;
.arrowDown {
  display: inline-block;
  width: 0;
  height: 0;
  overflow: hidden;
  border-left: $arrowWidth solid transparent;
  border-right: $arrowWidth solid transparent;
  border-top: $arrowWidth solid $LincolnOrange;
}

button {
  border: none;
  width: 15em;
  margin: 1em 0;
  padding: 1em;
  @include font-size(1rem);
  text-transform: uppercase;
  background-color: $LincolnOrange;
  color: white;

  &.cancel {
    background-color: $LincolnGrayDark;
    margin-left: 1em;
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  max-width: 240px;
  margin-top: 10px;

  .upload-button {
    border: none;
    text-align: center;
    padding: 1em;
    text-transform: uppercase;
    background-color: $LincolnOrange;
    color: white;
  }

  input[type="file"] {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.previewBorder{
  border: .5px solid black;
  margin-top: 10px;
  margin-bottom: 10px;
}

.uploading-image {
  padding: 0 1em;
  display: flex;
}
</style>
