<template lang="html">
<div class="videos-listing">
  <div class="viewSelectContainer">
    <div class="addBtn" @click="addVideo" v-if="isAdmin">
      <div class="txt">add new video</div>
      <font-awesome-icon class="icon" :icon="['fas', 'file-plus']" />
    </div>
    <div class="viewSelector">
      <font-awesome-icon @click="toogleList('list')"
      class="icon list" :class="{ active: isList }" :icon="['fal', 'list']" />
      <font-awesome-icon @click="toogleList('grid')"
      class="icon grid" :class="{ active: isGrid }" :icon="['fa', 'th-large']" />
    </div>
  </div>
  <div class="box" :class="{ gridView: isGrid }">
    <LoadingOverlay v-show="showLoader" />
    <component
    v-bind:is="toggleListType"
    v-for="(video, key) in this.videos"
    :key="key"
    :id="video.id"
    :video="video"
    :isAdmin="isAdmin"
    @edit=editVideo(video)
    @refresh=getVideos()
    >
    </component>
  </div>
</div>
</template>

<script lang="js">
import LoadingOverlay from '../../loaders/LoadingOverlay.vue';
import VideoSubmission from './ResourceVideoSubmission.vue';
import VideoPreview from './ResourceVideoPreview.vue';
import VideoPreviewGrid from './ResourceVideoPreviewGrid.vue';

export default {
  name: 'RESOURCE_VIDEOS',
  components: {
    VideoPreview,
    VideoPreviewGrid,
    LoadingOverlay,
  },
  props: {
    resourceType: String,
  },
  data() {
    return {
      showLoader: false,
      toggleListType: VideoPreview,
      isList: true,
      isGrid: false,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters['userStore/isAdmin'];
    },
    videos() {
      return this.$store.getters['resourceStore/resourceLibrary'];
    },
  },
  methods: {
    async getVideos() {
      if (this.videos.length === 0) {
        this.showLoader = true;
      }
      const resp = await this.$store.dispatch('resourceStore/getResourceLibraryVideos');
      this.showLoader = false;
      return resp;
    },
    addVideo() {
      this.$modal.show(VideoSubmission, {
        videoType: this.resourceType,
      }, {
        name: 'VideoSubmission',
        adaptive: true,
        height: 'auto',
        width: '70%',
        scrollable: true,
      }, {
        'before-close': this.beforeClose,
      });
    },
    editVideo(video) {
      this.$modal.show(VideoSubmission, { videoprop: video }, {
        name: 'VideoSubmission',
        adaptive: true,
        height: 'auto',
        width: '70%',
        scrollable: true,
      }, {
        'before-close': this.beforeClose,
      });
    },
    // eslint-disable-next-line no-unused-vars
    beforeClose(event) {
      this.getVideos();
    },
    toogleList(name) {
      if (name === 'list') {
        this.toggleListType = VideoPreview;
        this.isGrid = false;
        this.isList = true;
      } else if (name === 'grid') {
        this.toggleListType = VideoPreviewGrid;
        this.isGrid = true;
        this.isList = false;
      }
    },
  },
  async activated() {
    await Promise.all([this.getVideos()]);
  },
};
</script>

<style scoped lang="scss">
.videos-listing {
  background: #fff;
  color: #304048;

  .viewSelectContainer {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 50px;
    border-bottom: 0.5px solid #dadbdf;

    .addBtn {
      transition: all 0.35s ease;
      display: flex;
      align-items: center;
      @include font-size(1.25rem);
      text-transform: uppercase;

      &:hover {
        cursor: pointer;
        color: $LincolnOrange;
      }

      .txt {
        margin-right: 10px;
      }
    }

    .viewSelector {
      position: absolute;
      right: 0;

      .icon {
        @include font-size(1.5rem);

        &:hover {
          color: $LincolnOrange;
          cursor: pointer;
        }

        &.active {
          color: $LincolnOrange;
        }
      }

      .grid {
        margin-left: 20px;
      }
    }
  }

  .box {
    width: 100%;
    padding: 1rem 0;
    color: #304048;

    &.gridView {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
    }
  }
}
</style>
