<template>
  <div class="toggle-container" @click="onClick">
    <div v-if="isSwitchOn" class="toggle-control">
      <font-awesome-icon :icon="['fas', 'toggle-on']" size="2x" class="switch-on"/>
    </div>
    <div v-else  class="toggle-control">
      <font-awesome-icon :icon="['fas', 'toggle-on']" size="2x" rotation="180" class="switch-off"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isSwitchOn: false,
    };
  },
  methods: {
    onClick() {
      this.isSwitchOn = !this.isSwitchOn;
      this.$emit('toggled', this.isSwitchOn);
    },
  },
  created() {
    this.isSwitchOn = this.value;
  },
};
</script>

<style scoped>
  .toggle-container{
  }

  .toggle-control{
    cursor: pointer;

  }

  .toggle-control :hover{
    opacity: 80%;
  }

  .switch-on{
    color: royalblue;
  }

  .switch-off{
    color: slategray;
  }


</style>
