<template>
  <div id="videoSubmission">
    <div class="form-title">Add a Video</div>
    <form @submit.prevent="submit">
      <TextBox
        :label="'Title'"
        :name="'title'"
        v-model="video.title"
        :required="true"
        :placeholder="'Title *'"
        class="textBox"
      />
      <TextBox
        :name="'description'"
        :required="true"
        v-model="video.description"
        :type="'textarea'"
        :placeholder="'Short description *'"
        class="textBox description"
      />
      <TextBox
        :label="'Vimeo Video ID'"
        :name="'videoId'"
        v-model="video.videoId"
        :required="true"
        :placeholder="'Vimeo Video ID *'"
        class="textBox"
      />
      <ImageCropperForAsset class="imagePreviewer"
                            :asset="video.thumbnailAsset"
                            @sendImage="sendImage"
                            @uploadImage="uploadImage" />
      <div>Video Listing Preview</div>
      <VideoPreview
        class="previewBorder"
        :video="video"
        :defaultImage="imageData"
      />
      <ButtonSubmit :copy="'Submit'" @callback="submit" />
      <ButtonCancel class="cancel" @click="closeModal" />
    </form>
  </div>
</template>

<script>
import TextBox from '../../formElements/TextBox.vue';
import ImageCropperForAsset from '../../imageManipulation/ImageCropperForAsset.vue';
import VideoPreview from './VideoPreview.vue';
import ButtonSubmit from '../../formElements/buttons/buttonSubmit.vue';
import ButtonCancel from '../../formElements/buttons/ButtonCancel.vue';

export default {
  name: 'VideoSubmission',
  components: {
    TextBox,
    ButtonSubmit,
    ImageCropperForAsset,
    VideoPreview,
    ButtonCancel,
  },
  props: {
    method: { type: Function },
  },
  data: () => ({
    imageData: null,
    imageFormData: null,
    video: {
      title: '',
      description: '',
      thumbnailAssetId: null,
      videoId: null,
      thumbnailAsset: null,
      videoType: null,
    },
  }),
  methods: {
    async submit() {
      const thumbnailImageUploaded = await this.$store.dispatch('assetStore/setAsset', this.imageFormData);
      this.video.thumbnailAssetId = thumbnailImageUploaded.id;
      this.video.videoType = 'GUILD';

      await this.$store.dispatch('resourceStore/submitVideo', this.video);
      this.$store.dispatch('buttonSubmitStore/done');
      this.closeModal();
    },
    sendImage(image) {
      this.imageData = image;
    },
    async uploadImage(file) {
      this.imageFormData = file;
    },
    closeModal() {
      this.$modal.hide('VideoSubmission');
    },
  },
};
</script>

<style lang="scss" scoped>
#videoSubmission {
  padding: 2em;
  background-color: white;

  .form-title {
    @include font-size(2rem);
  }
}

.options {
  color: $LincolnGrayLight;
}

.textBox {
  margin: 2em 0;
  display: block;

  &.description {
    /deep/ textarea {
      height: 125px;
    }
  }
}

$arrowWidth: 0.7em;
.arrowDown {
  display: inline-block;
  width: 0;
  height: 0;
  overflow: hidden;
  border-left: $arrowWidth solid transparent;
  border-right: $arrowWidth solid transparent;
  border-top: $arrowWidth solid $LincolnOrange;
}

.cancel {
  margin-left: 1rem;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  max-width: 240px;
  margin-top: 10px;

  .upload-button {
    border: none;
    text-align: center;
    padding: 1em;
    text-transform: uppercase;
    background-color: $LincolnOrange;
    color: white;
  }

  input[type="file"] {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.previewBorder{
  border: .5px solid black;
  margin-top: 10px;
  margin-bottom: 10px;
}

.uploading-image {
  padding: 0 1em;
  display: flex;
}
</style>
