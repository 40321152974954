var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "file-select" }, [
    _c("div", { staticClass: "select-button" }, [
      _vm.value
        ? _c("div", [
            _vm._v("\n      Selected File:\n      "),
            _c("strong", [_vm._v(_vm._s(_vm.value.name))]),
          ])
        : _c("div", [_vm._v("Upload a File")]),
    ]),
    _c("input", {
      ref: "DocAsset",
      attrs: {
        type: "file",
        name: "DocAsset",
        id: "DocAsset",
        required: true,
        accept: _vm.fileTypes,
      },
      on: { change: _vm.handleFileChange },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }