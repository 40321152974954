<template>
  <div class="imageCropperForResource">
    <div class="title">Thumbnail Uploader</div>
    <input type="file" name="image" accept="image/*"
           class="uploadFile"
           @change="setImage" />
    <div class="ImageCropperBtn" @click.prevent="rotate" v-if="imgSrc != ''">Rotate</div>
    <div class="cropperContainer">
      <vue-cropper v-show="imgSrc != ''"
          ref='cropper'
          :guides="true"
          :view-mode="2"
          drag-mode="crop"
          :aspectRatio="aspectRatio"
          :auto-crop-area="0.5"
          :min-container-width="100"
          :min-container-height="180"
          :background="true"
          :rotatable="true"
          :src="imgSrc"
          alt=""
          :cropend="cropImage"
          :ready="cropImage"
          :img-style="{ 'width': '250px', 'height': '250px' }">
      </vue-cropper>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';

export default {
  name: 'ImageCropperForResource',
  props: ['asset'],
  components: {
    VueCropper,
  },
  data() {
    return {
      imgSrc: '',
      cropImg: this.asset,
      newWidth: 300,
      aspectRatio: 1,
      resizedCanvas: null,
      fileName: null,
      fileType: null,
    };
  },
  methods: {
    setImage(e) {
      const file = e.target.files[0];
      this.fileName = file.name;
      this.fileType = file.type;
      if (!file.type.includes('image/')) {
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    cropImage() {
      const croppedCanvas = this.$refs.cropper.getCroppedCanvas();

      this.resizedCanvas = document.createElement('canvas');
      const resizedContext = this.resizedCanvas.getContext('2d');

      const aspectRatio = croppedCanvas.width / croppedCanvas.height;
      const newHeight = this.newWidth / aspectRatio;

      this.resizedCanvas.height = newHeight;
      this.resizedCanvas.width = this.newWidth;

      const canvas = croppedCanvas;

      resizedContext.drawImage(canvas, 0, 0, this.newWidth, newHeight);
      this.cropImg = this.resizedCanvas.toDataURL();
      this.$emit('sendImage', this.cropImg);
      this.selectedImage();
    },
    rotate() {
      this.$refs.cropper.rotate(90);
    },
    async selectedImage() {
      this.resizedCanvas.toBlob(this.gotBlob);
    },
    async gotBlob(blob) {
      const blobObject = blob;
      const formData = new FormData();
      blobObject.FileName = this.fileName;
      blobObject.ModifiedOn = new Date();
      formData.append('file', blob, this.fileName);
      this.$emit('uploadImage', formData);
    },
  },
};
</script>

<style lang="scss" scoped>
.imageCropperForResource {

  border: .5px solid $LincolnOrange;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;

  .title {
    text-transform: uppercase;
    padding-bottom: 10px;
  }

  .uploadFile {
    font-size: 1.2em;
    padding: 10px 0;
  }

  .cropperContainer {
    width: 250px;
    height: 250px;
    border: 1px solid gray;
  }

  .imgPreviewContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .imgPreviewContainer > * {
    padding: 10px;
  }

  .ImageCropperBtn {
    background-color: $LincolnOrange;
    color: white;
    width: 100px;
    text-align: center;
    text-transform: uppercase;
    padding: 5px;
    margin-bottom: 5px;
  }
}
</style>
