<template>
  <label class="file-select">
    <div class="select-button">
      <div v-if="value">
        Selected File:
        <strong>{{value.name}}</strong>
      </div>
      <div v-else>Upload a File</div>
    </div>
    <input
      type="file"
      :name="'DocAsset'"
      id="DocAsset"
      ref="DocAsset"
      :required="true"
      :accept="fileTypes"
      @change="handleFileChange"
    />
  </label>
</template>

<script>
export default {
  props: {
    value: File,
    accept: String,
  },
  data() {
    return {
      acceptTypes: '.pdf, .doc, .docx, .xls, .xlsx, .pptx, .png, .jpg, .jpeg,.gif, .mp3, .mp4',
    };
  },
  methods: {
    handleFileChange(e) {
      if (this.fileTypes
        .includes(e.target.files[0].name.substring(e.target.files[0].name.length - 3))) {
        this.$emit('input', e.target.files[0]);
      } else {
        e.target.files = null;
      }
    },
  },

  computed: {
    fileTypes() {
      return this.accept ? this.accept : this.acceptTypes;
    },
  },
  watch: {
    accept(newAccept, oldAccept) {
      // eslint-disable-next-line no-console
      console.log('newAccept', newAccept, 'oldAccept', oldAccept);
    },
  },
};
</script>

<style scoped lang="scss">
.file-select {
  position: relative;
  display: block;
  width: 100%;
  max-width: 240px;

  .select-button {
    border: none;
    text-align: center;
    padding: 1em;
    text-transform: uppercase;
    background-color: $LincolnOrange;
    color: white;
  }

  input[type="file"] {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
