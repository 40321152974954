var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "documents-listing" }, [
    _c("div", { staticClass: "viewSelectContainer" }, [
      _vm.isAdmin
        ? _c(
            "div",
            { staticClass: "addBtn", on: { click: _vm.addDocument } },
            [
              _c("div", { staticClass: "txt" }, [_vm._v("add new document")]),
              _c("font-awesome-icon", {
                staticClass: "icon",
                attrs: { icon: ["fas", "file-plus"] },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "viewSelector" },
        [
          _c("font-awesome-icon", {
            staticClass: "icon list",
            class: { active: _vm.isList },
            attrs: { icon: ["fal", "list"] },
            on: {
              click: function ($event) {
                return _vm.toogleList("list")
              },
            },
          }),
          _c("font-awesome-icon", {
            staticClass: "icon grid",
            class: { active: _vm.isGrid },
            attrs: { icon: ["fa", "th-large"] },
            on: {
              click: function ($event) {
                return _vm.toogleList("grid")
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "box", class: { gridView: _vm.isGrid } },
      [
        _c("LoadingOverlay", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showLoader,
              expression: "showLoader",
            },
          ],
        }),
        _vm._l(this.documents, function (document) {
          return _c(_vm.toggleListType, {
            key: "document" + document.id,
            tag: "component",
            attrs: { documentObject: document, isAdmin: _vm.isAdmin },
          })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }