import { render, staticRenderFns } from "./GuildVideosListing.vue?vue&type=template&id=fb8447d2&scoped=true&lang=html"
import script from "./GuildVideosListing.vue?vue&type=script&lang=js"
export * from "./GuildVideosListing.vue?vue&type=script&lang=js"
import style0 from "./GuildVideosListing.vue?vue&type=style&index=0&id=fb8447d2&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb8447d2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("c:\\VSTSAgentPreProd\\_work\\103\\s\\effortless-guide-site\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fb8447d2')) {
      api.createRecord('fb8447d2', component.options)
    } else {
      api.reload('fb8447d2', component.options)
    }
    module.hot.accept("./GuildVideosListing.vue?vue&type=template&id=fb8447d2&scoped=true&lang=html", function () {
      api.rerender('fb8447d2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/resources/guildVideos/GuildVideosListing.vue"
export default component.exports