<template lang="html">
<div class="video">
  <div class="wrapper">
    <div class="thumbnailWrapper">
      <a href="#" @click.prevent="openVideoModal">
        <div class="thumbnail">
          <AssetDownload :asset="video.thumbnailAsset" :forceImage="image"/>
        </div>
      </a>
    </div>
    <div class="descWrapper">
      <a href="#" class="videoTitle" @click.prevent="openVideoModal">
        {{video.title}}
      </a>
      <div class="videoDescription">
        {{video.description | truncate}}
      </div>
    </div>
    <div class="deleteVideo" v-if="isAdmin">
      <ButtonDelete @callback="deleteConfirm" />
    </div>
  </div>
</div>
</template>

<script lang="js">
import ResourceHandlers from '../../../mixins/resourceHandlers.vue';
import ButtonDelete from '../../formElements/buttons/buttonDelete.vue';
import AssetDownload from '../../asset/AssetDownload.vue';

export default {
  name: 'VideoPreview',
  props: ['video', 'defaultImage', 'isAdmin'],
  components: {
    ButtonDelete,
    AssetDownload,
  },
  mixins: [ResourceHandlers],
  data() {
    return {
      image: '',
    };
  },
  watch: {
    defaultImage(newValue) {
      this.image = newValue;
    },
  },
  methods: {
    async deleteConfirm() {
      this.$dialog
        .confirm('Are you sure you want to remove this video?', { okText: 'Yes', cancelText: 'No', customClass: '' })
        .then(this.deleteVideo)
        .then(() => this.$store.dispatch('buttonSubmitStore/done'))
        .catch(() => this.$store.dispatch('buttonSubmitStore/done'));
    },
    async deleteVideo() {
      await this.$store.dispatch('resourceStore/deleteVideo', {
        videoId: this.video.id,
        assetId: this.video.thumbnailAssetId,
      }).catch(async (err) => {
        if (err.message.indexOf('409') >= 0) {
          const message = 'Cannot delete resource because it is '
            + 'being referenced by the New and Noteworthy Carousel';
          await this.$dialog
            .confirm(message, {
              okText: 'OK',
              cancelText: null,
              customClass: 'hideCancel',
            });
        }
      }).finally(() => this.$store.dispatch('buttonSubmitStore/done'));
      await this.$parent.getVideos();
    },
    openVideoModal() {
      this.$analytics.trackEventBtnPageWithMetaInfo('Video', {
        videoId: this.video.id,
      });
      this.showVideoModal(this.video.id);
    },
  },
  filters: {
    truncate(value) {
      let newValue = value;
      if (value.length > 40) {
        newValue = `${value.substring(0, 40)}...`;
      }
      return newValue;
    },
  },
};
</script>

<style scoped lang="scss">
.video {
  color: #262a2e;
  margin-bottom: 10px;
  position: relative;
  padding: 10px;

  .wrapper {
    background: #ececec;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    border-radius: 10px;
    overflow: hidden;

    .thumbnailWrapper {
      flex: 1;

      .thumbnail {
        width: 75px;
        height: 75px;
      }
    }
  }

  .descWrapper {
    flex: 12;
    padding-left: 10px;

    .videoTitle {
      @include font-size(1.5rem);
      text-decoration: none;
      color: rgb(50, 64, 74);

      &:hover {
        color: $LincolnOrange;
      }
    }

    .videoDescription {
      color: #262a2e;
      font-size: 1rem;
    }
  }

  .deleteVideo {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
