<template lang="html">
<div class="document">
  <div class="wrapper">
    <div class="thumbnailWrapper">
      <a href="#" @click.prevent="download">
        <div class="thumbnail">
          <AssetDownload :asset="documentObject.thumbnailAsset" :forceImage="image"/>
        </div>
      </a>
    </div>
    <div class="descWrapper">
      <a href="#" class="documentTitle" @click.prevent="download">
        {{documentObject.title}}
      </a>
      <div class="documentDescription">
        {{documentObject.description | truncate}}
      </div>
    </div>
    <div class="toggleSwitch" v-if="isAdmin">
      <ToggleSwitch @toggled="toggleEnabled" :value="documentObject.isEnabled"></ToggleSwitch>
    </div>
    <div class="deleteDocument" v-if="isAdmin">
      <ButtonDelete @callback="deleteConfirm" />
    </div>
  </div>
</div>
</template>

<script lang="js">
import ButtonDelete from '../../formElements/buttons/buttonDelete.vue';
import ToggleSwitch from '@/components/formElements/ToggleSwitch.vue';
import ResourceHandlers from '../../../mixins/resourceHandlers.vue';
import AssetDownload from '../../asset/AssetDownload.vue';
import { toggleDocumentEnabled } from '@/helpers/toggleEnabledHelper';

export default {
  name: 'DocumentPreview',
  props: ['documentObject', 'defaultImage', 'isAdmin'],
  components: {
    ButtonDelete,
    AssetDownload,
    ToggleSwitch,
  },
  mixins: [ResourceHandlers],
  data() {
    return {
      image: this.defaultImage || '',
      asset: {},
      cleanFileName: '',
    };
  },
  watch: {
    defaultImage(newValue) {
      this.image = newValue;
    },
  },
  methods: {
    async deleteConfirm() {
      this.$dialog
        .confirm('Are you sure you want to remove this document?', {
          okText: 'Yes',
          cancelText: 'No',
          customClass: '',
        })
        .then(this.deleteDocument)
        .then(() => this.$store.dispatch('buttonSubmitStore/done'))
        .catch(() => this.$store.dispatch('buttonSubmitStore/done'));
    },
    async toggleEnabled() {
      this.documentObject.isEnabled = !this.documentObject.isEnabled;
      toggleDocumentEnabled(this.documentObject.id);
    },
    async deleteDocument() {
      await this.$store.dispatch('resourceStore/deleteDocument', {
        documentId: this.documentObject.id,
        docAssetId: this.documentObject.docAssetId,
        thumbnailAssetId: this.documentObject.thumbnailAssetId,
      }).catch(async (err) => {
        if (err.message.indexOf('409') >= 0) {
          const message = 'Cannot delete resource because it is '
            + 'being referenced by the New and Noteworthy Carousel';
          await this.$dialog
            .confirm(message, {
              okText: 'OK',
              cancelText: null,
              customClass: 'hideCancel',
            });
        }
      }).finally(() => this.$store.dispatch('buttonSubmitStore/done'));

      await this.$parent.getDocuments();
    },
    async download() {
      this.$analytics.trackEventBtnPageWithMetaInfo('Document', {
        documentId: this.documentObject.id,
      });

      await this.$downloader.downloadDocument(this.documentObject.id);
    },
  },
  filters: {
    truncate(value) {
      let newValue = value;
      if (value.length > 40) {
        newValue = `${value.substring(0, 40)}...`;
      }
      return newValue;
    },
  },
};
</script>

<style scoped lang="scss">
.document {
  color: #262a2e;
  margin-bottom: 10px;
  position: relative;
  padding: 10px;

  .wrapper {
    background: #ececec;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    border-radius: 10px;
    overflow: hidden;

    .thumbnailWrapper {
      flex: 1;

      .thumbnail {
        width: 75px;
        height: 75px;
      }
    }
  }

  .descWrapper {
    flex: 12;
    padding-left: 10px;

    .documentTitle {
      @include font-size(1.5rem);
      text-decoration: none;
      color: rgb(50, 64, 74);

      &:hover {
        color: $LincolnOrange;
      }
    }

    .documentDescription {
      color: #262a2e;
      font-size: 1rem;
    }
  }

  .toggleSwitch {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 40px;
  }

  .deleteDocument {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
