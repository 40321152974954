<template lang="html">
<div class="document">
  <div class="wrapper">
    <div class="thumbnailWrapper">
      <a href="#" @click.prevent="downloadDocument">
        <div class="thumbnail">
          <AssetDownload :asset="documentObject.thumbnailAsset" :forceImage="image"/>
        </div>
      </a>
    </div>
    <Accordion class="mb-4" v-if="documentObject.Year === 2024">
      <template v-slot:title>
        <span class="font-semibold text-xl">2024</span>
      </template>
      <template v-slot:content>
        <div class="descWrapper">
          <a href="#" class="documentTitle" @click.prevent="downloadDocument">
            {{documentObject.title}}
          </a>
          <div class="documentDescription">
            {{documentObject.description | truncate}}
          </div>
        </div>
        <div class="deleteDocument" v-if="isAdmin">
          <ButtonDelete @callback="deleteConfirm" />
        </div>
      </template>
    </Accordion>
    <Accordion class="mb-8" v-else-if="documentObject.Year === 2023">
      <template v-slot:title>
        <span class="font-semibold text-xl">2023</span>
      </template>
      <template v-slot:content>
        <div class="descWrapper">
          <a href="#" class="documentTitle" @click.prevent="downloadDocument">
            {{documentObject.title}}
          </a>
          <div class="documentDescription">
            {{documentObject.description | truncate}}
          </div>
        </div>
        <div class="deleteDocument" v-if="isAdmin">
          <ButtonDelete @callback="deleteConfirm" />
        </div>
      </template>
    </Accordion>
    <Accordion class="mb-4" v-else-if="documentObject.Year === 2022">
      <template v-slot:title>
        <span class="font-semibold text-xl">2022</span>
      </template>
      <template v-slot:content>
        <div class="descWrapper">
          <a href="#" class="documentTitle" @click.prevent="downloadDocument">
            {{documentObject.title}}
          </a>
          <div class="documentDescription">
            {{documentObject.description | truncate}}
          </div>
        </div>
        <div class="deleteDocument" v-if="isAdmin">
          <ButtonDelete @callback="deleteConfirm" />
        </div>
      </template>
    </Accordion>
    <div v-else>
      Nothing to see here.
    </div>
  </div>
</div>
</template>

<script lang="js">
import AssetDownload from '../../asset/AssetDownload.vue';
import ButtonDelete from '../../formElements/buttons/buttonDelete.vue';
import Accordion from '../../accordion/Accordion.vue';

export default {
  name: 'DocumentPreviewGrid',
  props: ['documentObject', 'defaultImage', 'isAdmin'],
  components: {
    AssetDownload,
    ButtonDelete,
    Accordion,
  },
  data() {
    return {
      image: this.defaultImage || '',
      asset: {},
      cleanFileName: '',
    };
  },
  watch: {
    defaultImage(newValue) {
      this.image = newValue;
    },
  },
  methods: {
    async deleteConfirm() {
      this.$dialog
        .confirm('Are you sure you want to remove this document?', {
          okText: 'Yes',
          cancelText: 'No',
          customClass: '',
        })
        .then(this.deleteDocument)
        .then(() => this.$store.dispatch('buttonSubmitStore/done'))
        .catch(() => this.$store.dispatch('buttonSubmitStore/done'));
    },
    async deleteDocument() {
      await this.$store.dispatch('resourceStore/deleteDocument', {
        documentId: this.documentObject.id,
        docAssetId: this.documentObject.docAssetId,
        thumbnailAssetId: this.documentObject.thumbnailAssetId,
      }).catch(async (err) => {
        if (err.message.indexOf('409') >= 0) {
          const message = 'Cannot delete resource because it is '
            + 'being referenced by the New and Noteworthy Carousel';
          await this.$dialog
            .confirm(message, {
              okText: 'OK',
              cancelText: null,
              customClass: 'hideCancel',
            });
        }
      }).finally(() => this.$store.dispatch('buttonSubmitStore/done'));

      await this.$parent.getDocuments();
    },
    cleanName(assetFileName, id) {
      this.cleanFileName = assetFileName.toString().replace(`_${id}`, '');
    },
    downloadAsset() {
      return this.$store.dispatch(
        'assetStore/downloadAsset',
        this.documentObject.docAssetId,
      );
    },
    async downloadDocument() {
      await this.$downloader.downloadDocument(this.documentObject.id);
    },
  },
  filters: {
    truncate(value) {
      let newValue = value;
      if (value.length > 100) {
        newValue = `${value.substring(0, 100)}...`;
      }
      return newValue;
    },
  },
};
</script>

<style scoped lang="scss">
.document {
  height: 550px;
  flex-basis: 100%;
  width: 259px;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  color: #262a2e;
  display: flex;
  flex-direction: column;

  @media (min-width: 425px) {
    flex-basis: 50%;
  }

  @media (min-width: 640px) {
    flex-basis: 33.33%;
  }

  @include respond(lg) {
    flex-basis: 25%;
  }

  .wrapper {
    background: #ececec;
    height: 100%;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    border-radius: 10px;
    overflow: hidden;

    .thumbnailWrapper {
      margin-bottom: 10px;
      height: 55%;

      .thumbnail {
        width: 100%;
        height: 100%;
      }
    }

    .descWrapper {
      padding: 0 20px 20px 20px;

      .documentTitle {
        @include font-size(1.5rem);
        text-decoration: none;
        color: rgb(50, 64, 74);
        display: block;
        margin-bottom: 10px;

        &:hover {
          color: $LincolnOrange;
        }
      }

      .documentDescription {
        color: #262a2e;
        font-size: 1rem;
      }
    }
  }

  .deleteDocument {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
