<template lang="html">
    <div class="documents-listing">
      <div class="viewSelectContainer">
        <div class="addBtn"
            @click="addDocument"
            v-if="isAdmin">
          <div class="txt">add new document</div>
          <font-awesome-icon class="icon" :icon="['fas', 'file-plus']" />
        </div>
        <div class="viewSelector">
          <font-awesome-icon @click="toogleList('list')"
          class="icon list" :class="{ active: isList }" :icon="['fal', 'list']" />
          <font-awesome-icon @click="toogleList('grid')"
          class="icon grid" :class="{ active: isGrid }" :icon="['fa', 'th-large']" />
        </div>
      </div>
      <div class="box" :class="{ gridView: isGrid }">
        <LoadingOverlay v-show="showLoader" />
        <component
          v-bind:is="toggleListType"
          v-for="(document) in this.documents"
          :key="'document' + document.id"
          :documentObject="document"
          :isAdmin="isAdmin">
        </component>
        </div>
    </div>
</template>

<script lang="js">
import DocumentSubmission from './DocumentSubmission.vue';
import DocumentPreview from './DocumentPreview.vue';
import DocumentPreviewGrid from './DocumentPreviewGrid.vue';
import LoadingOverlay from '../../loaders/LoadingOverlay.vue';

export default {
  name: 'DOCUMENTS',
  props: {
    resourceType: String,
  },
  components: {
    DocumentPreview,
    DocumentPreviewGrid,
    LoadingOverlay,
  },
  data() {
    return {
      showLoader: false,
      toggleListType: DocumentPreview,
      isList: true,
      isGrid: false,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters['userStore/isAdmin'];
    },
    documents() {
      return this.$store.getters['resourceStore/documents'];
    },
  },
  methods: {
    async getDocuments() {
      if (this.documents.length === 0) {
        this.showLoader = true;
      }
      const resp = await this.$store.dispatch('resourceStore/getDocuments', this.resourceType);
      this.showLoader = false;
      return resp;
    },
    addDocument() {
      this.$modal.show(DocumentSubmission, {
        resourceType: this.resourceType,
        accept: null,
      }, {
        name: 'DocumentSubmission',
        adaptive: true,
        height: 'auto',
        width: '70%',
        scrollable: true,
      });
    },
    toogleList(name) {
      if (name === 'list') {
        this.toggleListType = DocumentPreview;
        this.isGrid = false;
        this.isList = true;
      } else if (name === 'grid') {
        this.toggleListType = DocumentPreviewGrid;
        this.isGrid = true;
        this.isList = false;
      }
    },
  },
  async activated() {
    await Promise.all([this.getDocuments()]);
  },
};

</script>

<style scoped lang="scss">
.documents-listing {
  background: #fff;
  color: #304048;

  .viewSelectContainer {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 50px;
    border-bottom: 0.5px solid #dadbdf;

    .addBtn {
      transition: all 0.35s ease;
      display: flex;
      align-items: center;
      @include font-size(1.25rem);
      text-transform: uppercase;

      &:hover {
        cursor: pointer;
        color: $LincolnOrange;
      }

      .txt {
        margin-right: 10px;
      }
    }

    .viewSelector {
      position: absolute;
      right: 0;

      .icon {
        @include font-size(1.5rem);

        &:hover {
          color: $LincolnOrange;
          cursor: pointer;
        }

        &.active {
          color: $LincolnOrange;
        }
      }

      .grid {
        margin-left: 20px;
      }
    }
  }

  .box {
    width: 100%;
    padding: 1rem 0;
    color: #304048;

    &.gridView {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
    }
  }
}
</style>
