<template>
    <div>
      <div class="resource-container">
        <div class="section-title">Documents</div>
        <DocumentListing :resourceType="resourceType"></DocumentListing>
      </div>
      <div class="resource-container">
        <div class="section-title">Videos</div>
        <VideoListing :resourceType="resourceType"></VideoListing>
      </div>
    </div>
</template>

<script>
import DocumentListing from '../documents/DocumentsListing.vue';
import VideoListing from '../videos/VideosListing.vue';

export default {
  name: 'IntensiveAssetsList',
  components: { DocumentListing, VideoListing },
  data() {
    return {
      resourceType: 'INTENSIVE_ASSET',
    };
  },
};

</script>

<style lang="scss" scoped>
  .resource-container{
    border: 2px solid #cccccc;
    border-radius: 1em;
    padding: 5px;
    margin-bottom: 2em;

    .section-title{
      font-size: 1.5em;
      text-transform: uppercase;
      text-decoration: underline;
      text-align: center;
    }
  }
</style>
