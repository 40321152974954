var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "imgContainer" }, [
    _c(
      "div",
      { staticClass: "loadingContainer" },
      [
        _vm.loading
          ? _c("font-awesome-icon", {
              staticClass: "fa-spin loading",
              attrs: { icon: ["fas", "cog"], size: "1x" },
            })
          : _vm._e(),
      ],
      1
    ),
    _c("div", { class: _vm.storyTile, style: _vm.setAssetImage }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }