<template>
  <div class="buttonEdit">
    <font-awesome-icon
      class="icon"
      :class="buttonDisable"
      @click.prevent="clickedButton"
      :icon="['fa', 'pen-square']"
      size="2x"
    />
  </div>
</template>

<script lang="js">

export default {
  name: 'ButtonEdit',
  created() {
    this.$store.dispatch('buttonSubmitStore/done');
  },
  methods: {
    clickedButton() {
      this.$store.dispatch('buttonSubmitStore/submit');
      this.$emit('callback');
    },
  },
  computed: {
    buttonDisable() {
      if (this.submitting) {
        return 'disable';
      }
      return '';
    },
    submitting() {
      return this.$store.getters['buttonSubmitStore/submitting'];
    },
    message() {
      return this.$store.getters['buttonSubmitStore/message'];
    },
  },
};
</script>

<style lang="scss" scoped>
.buttonEdit {
  .icon {
    color: rgb(1, 94, 1);
    opacity: 0.8;
    background-image: radial-gradient(at center, white 40%, transparent 40%);
    border-radius: 50%;
    transition: all 0.3s;

    &:hover {
      opacity: 1;
      background-image: radial-gradient(at center, black 40%, transparent 40%);
      cursor: pointer;
    }

    &.disable {
      color: $LincolnGrayDark;
      pointer-events: none;
    }
  }
}
</style>
