var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "buttonDeleteFull" }, [
    _c(
      "button",
      {
        staticClass: "button",
        class: {
          sized: !_vm.allowSizing,
          disable: _vm.submitting || !_vm.enabled,
        },
        attrs: { disabled: !_vm.enabled },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.clickedButton($event)
          },
        },
      },
      [_vm._v("\n  " + _vm._s(_vm.copy) + "\n  ")]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.submitting,
            expression: "submitting",
          },
        ],
        staticClass: "submitting",
      },
      [
        _c("font-awesome-icon", {
          staticClass: "fa-spin",
          attrs: { icon: ["fas", "cog"], size: "1x" },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.message,
            expression: "message",
          },
        ],
        staticClass: "message",
      },
      [_vm._v("Request Failed\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }